<template>
  <rir-cards-block>
    <a
      class="px-5"
      @click="$attrs.closeModal(null)"
      slot="status">Назад</a>
    <div
      slot="title"
      class="px-5 d-flex flex-column">
    <span
      class="nomira mb-2">
      Список {{ hasMany.name }}
    </span>
      <rir-input
        label="Поиск"
        v-model="search"
      >
        <rir-icon
          class="anie"
          fill="titanic"
          icon="search"
          slot="before" />
      </rir-input>
    </div>
    <rir-list
      class="px-5">
      <rir-list-item
        v-for="l in list.data"
        :key="l.id"
        @click.native="selectEntity(l)"
      >
        {{ l.name }}
      </rir-list-item>
    </rir-list>
  </rir-cards-block>
</template>

<script>
import ListResponse from '@/models/ListResponse'

export default {
  name: 'modal-list-entity',
  mounted () {
    this.loadData()
  },
  data: () => ({
    search: null,
    list: []
  }),
  props: {
    hasMany: {
      type: Object
    },
    excludeEntity: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    entity () {
      return this.hasMany.entity
    }
  },
  methods: {
    async loadData () {
      const { data } = await this.$axios
        .get(this.entity.config.link)
      this.list = new ListResponse(data, this.entity)
      this.list.data = this.list.data.filter(e => {
        const arr = this.excludeEntity.filter(ee => ee.id === e.id)
        return (arr.length === 0)
      })
    },
    selectEntity (el) {
      this.$attrs.closeModal(el)
    }
  }
}
</script>
