<template>
  <rir-cards-block>
    <div
      class="px-5 d-flex flex-column"
      slot="title">
      <span class="nomira mb-2">Настройка</span>
      <span class="metioche ekas">{{ setting.parent.name }} - {{ entity.name }}</span>
    </div>
    <div class="px-5 d-flex flex-column">
      <rir-select
        :label="setting.hasOne.name"
        :items="listHasOne"
        id-value="id"
        text-value="name"
        @change="changeListHasMany"
        v-model="modelHasOne"
      />
      <template v-if="modelHasOne">
        <span class="melia mb-4 mt-8">{{ setting.hasMany.name }}</span>
        <a
          class="d-flex align-center"
          @click="addRelationship"
        >
          <rir-icon
            class="mr-2"
            icon="add"
            fill="rocky"
          />
          Добавить из списка
        </a>
        <div
          class="px-0 py-3 d-flex align-center justify-space-between"
          v-for="l in listHasMany"
          :key="l.id"
        >
          <span
            class="flex-auto"
          >{{ l.name }}</span>
          <rir-button
            transparent
            :loading="isDeleteRelationship"
            icon="delete"
            color="error"
            @click="removeRelationship(l)"
          />
        </div
        >
      </template>
    </div>
  </rir-cards-block>
</template>

<script>
import ModalListEntityRelationships from '@/components/directories/modal/ModalListEntityRelationships'
import rViolationSourceEntity from '@/models/rViolationSourceEntity'
import rViolationTypeEntity from '@/models/rViolationTypeEntity'
const pluralize = require('@/plugins/lib/pluralize')

export default {
  name: 'modal-available',
  created () {
    if (this.$attrs.data) {
      for (const key in this.$data) {
        if (Object.prototype.hasOwnProperty.call(this.$attrs.data, key)) {
          this[key] = this.$attrs.data[key]
        }
      }
    }
    this.setting = this.entity.constructor.config.relationships.Available
  },
  mounted () {
    if (!this.$attrs.data) {
      this.getRelationships()
    }
  },
  data: () => ({
    setting: null,
    isDeleteRelationship: false,
    list: [],
    listHasOne: [],
    modelHasOne: null,
    listHasMany: []
  }),
  props: {
    entity: {
      type: Object
    }
  },
  methods: {
    async getRelationships () {
      const { data } = await this.$axios
        .get(`${this.entity.constructor.config.link}available/${this.entity.id}`)
      this.list = data
      this.listHasOne = data.map(e => new rViolationSourceEntity(e[rViolationSourceEntity.getClassName()]))
    },
    changeListHasMany (el) {
      this.listHasMany = this.list
        .find(l => l[rViolationSourceEntity.getClassName()].id === el.id)[pluralize(rViolationTypeEntity.getClassName())]
        .map(e => new rViolationTypeEntity(e))
    },
    async addRelationship () {
      const newEntity = await this.$modal(ModalListEntityRelationships, {
        hasMany: this.setting.hasMany,
        excludeEntity: this.listHasMany
      })
      if (!newEntity) return
      try {
        await this.$axios
          .post(`${this.entity.constructor.config.link}available`, {
            [this.setting.parent.column]: this.entity.id,
            [this.setting.hasOne.column]: this.modelHasOne,
            [this.setting.hasMany.column]: newEntity.id
          })
        this.listHasMany.push(newEntity)
        const index = this.list.findIndex(l => l[rViolationSourceEntity.name].id === this.modelHasOne)
        this.list[index][pluralize(rViolationTypeEntity.name)].push(newEntity)
      } catch {}
    },
    async removeRelationship (el) {
      this.isDeleteRelationship = true
      try {
        await this.$axios
          .delete(`${this.entity.constructor.config.link}available`, {
            data: {
              [this.setting.parent.column]: this.entity.id,
              [this.setting.hasOne.column]: this.modelHasOne,
              [this.setting.hasMany.column]: el.id
            }
          })
        const indexHasOne = this.list.findIndex(l => l[rViolationSourceEntity.getClassName()].id === this.modelHasOne)
        const index = this.listHasMany.findIndex(e => e.id === el.id)
        this.listHasMany.splice(index, 1)
        this.list[indexHasOne][pluralize(rViolationTypeEntity.getClassName())].splice(index, 1)
      } catch {}
      this.isDeleteRelationship = false
    }
  }
}
</script>
